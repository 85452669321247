* {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    border: none;
}

html {
    background-color: black;
    height: 100vh;
    height: -webkit-fill-available;
}

a {
    text-decoration: none;
    color: #fff;
    font-weight: inherit;
}

/* body {
    margin: 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

body::-webkit-scrollbar {
    display: none;
} */

body {
    overflow: overlay;
    height: 100vh;
    height: -webkit-fill-available;
}

*::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 100;
}

*::-webkit-scrollbar {
    width: 7px;
    z-index: 100;
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 100;
}

.title-header {
    opacity: 0;
    animation: fade-in-title 0.75s 0s cubic-bezier(0.75, 0, 0, 0.75) forwards;
    margin-left: 15px;
}

h1 {
    font-size: min(50px, 6.5vw);
    font-weight: 100;
    margin: 0px;
    user-select: none;
}

.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
    background-color: #000;
    width: 100%;
    overflow: hidden;
}

.big-test-div {
    background-color: #61dafb;
    width: 100vw;
    height: 100vh;
}

.fullscreen-gallery {
    margin-top: 75px;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}

.carousel {
    height: min(100vw, 1080px);
}

.fullscreen-gallery::-webkit-scrollbar {
    display: none;
}

.fullscreen-image {
    width: auto;
    height: min(100vw, 1080px);
}

.nav-link-button {
    cursor: pointer;
    font-size: min(15px, 4vw);
    font-weight: 400;
    border-width: 1px;
    border-color: white;
    padding: 5px 10px 5px 10px;
    border-style: solid;
}

.app-header {
    height: 75px;
    position: fixed;
    top: 0;
    left: 0px;
    z-index: 10;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

.header-nav-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-right: 15px;
}

.app-link {
    color: #61dafb;
}

.carousel {
    opacity: 0;
    animation: fade-in 2s 1s cubic-bezier(0.75, 0, 0, 0.75) forwards;
}

/* Animations */
@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes fade-in-suggestion {
    from {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-in-title {
    from {
        opacity: 0.5;
        filter: blur(10px);
    }
    to {
        opacity: 1;
        filter: blur(0px);
    }
}

/* no circle */
.flickity-button {
    background: transparent !important;
}
/* big previous & next buttons */
.flickity-prev-next-button {
    width: 50px !important;
    height: 50px !important;
    border: none !important;
}
/* icon color */
.flickity-button-icon {
    fill: white !important;
    filter: drop-shadow(0px 0px 5px rgb(0 0 0 / 0.6));
    animation: fade-out 1s 0.5s ease forwards;
}
/* hide disabled button */
.flickity-button:disabled {
    display: none;
}
/* position dots up a bit */
.flickity-page-dots {
    bottom: 5px !important;
}
/* dots are lines */
.flickity-page-dots .dot {
    height: 2px !important;
    width: 40px !important;
    margin: 0 !important;
    border-radius: 0 !important;
    background-color: rgba(255, 255, 255, 0.4) !important;
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Mobile compatibility */

@media (max-width: 700px) {
    .app-header {
        height: 50px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .header-nav-links {
        display: none;
        margin-left: 15px;
        justify-content: flex-start;
        width: 100%;
    }

    .nav-link-button {
        border-width: 0px;
        border-bottom-width: 1px;
        padding: 0;
    }

    .fullscreen-gallery {
        margin-top: 50px;
    }
}

@media (min-width: 700px) {
    .mobile-header-nav-links {
        display: none;
    }
}

/* Hamburger stylers */
/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 10px;
    top: 13px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #eeeeee;
    height: 1px !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #ffffff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    top: 50px !important;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: rgba(0, 0, 0, 0.8);
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    overflow: hidden !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    height: calc(100% + 50px) !important;
    top: -50px !important;
    background: rgba(0, 0, 0, 0.3);
}

.about-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
    background-color: #000;
    width: 100%;
    overflow: hidden;
    min-height: 100vh;
}

.about-app-holder {
    height: 100vh;
    height: -webkit-fill-available;
    overflow: hidden;
}

.about-container {
    display: flex;
    flex-direction: column;
    margin-top: 75px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
}

.videos-container {
    display: flex;
    flex-direction: column;
    margin-top: 75px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
}

.about-card {
    opacity: 1;
    animation: fade-in-slide-from-bottom 1.4s 0s
        cubic-bezier(0.4, 0, 0.15, 0.99) forwards;
    width: min(calc(95vw - 30px), 970px);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.about-card > * > h1 {
    opacity: 0;
    animation: fade-in-slide-from-bottom 1.4s 0s
        cubic-bezier(0.4, 0, 0.15, 0.99) forwards;
    text-align: left;
    font-weight: 200;
    padding: 1em;
    color: #fff;
    text-transform: uppercase;
}

.about-card > * > h3 {
    opacity: 0;
    animation: fade-in-slide-from-bottom 1.4s 0s
        cubic-bezier(0.4, 0, 0.15, 0.99) forwards;
    text-align: left;
    font-weight: 200;
    padding: 0em 1em 0em 1em;
    margin-top: 1.5em;
    margin-bottom: 0em;
    color: #fff;
    text-transform: uppercase;
}

.about-card > * > p {
    color: #eee;
    animation: fade-in-slide-from-bottom 1.4s 0.2s
        cubic-bezier(0.4, 0, 0.15, 0.99) forwards;
    font-weight: 200;
    text-align: left;
    font-size: 0.75em;
    padding: 0em 1.7em 0em 1.7em;
    margin-bottom: 2em;
}

.about-card-text {
    max-height: 80%;
}

.about-card-footer {
    z-index: 10;
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

.wes-howell-icon {
    height: 100%;
    width: auto;
    animation: fade-in-slide-from-wes-howell-icon 2s 0s
        cubic-bezier(0.4, 0, 0.15, 0.99) forwards;
}

.videos-flex-box {
    display: flex;
    place-content: center space-evenly;
    flex-wrap: wrap;
    max-width: 90%;
    justify-content: center;
    margin: 1em;
    margin-bottom: 5em;
}

.video-container {
    position: relative;
    border-style: solid;
    border-width: 0px;
    border-color: white;
    height: 300px;
    width: 500px;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 300px;
    max-width: 500px;
}

@keyframes fade-in-slide-from-bottom {
    from {
        opacity: 0;
        transform: translate(0, 20px);
    }
    to {
        opacity: 1;
        transform: translate(0, 0px);
    }
}

@keyframes fade-in-slide-from-wes-howell-icon {
    from {
        transform: translate(0px, 100px);
        filter: blur(10px) brightness(0);
    }
    to {
        opacity: 1;
        transform: translate(0px, 0px);
        filter: blur(0px) brightness(0.1);
    }
}

@media (max-width: 600px) {
    .video-container {
        position: relative;
        border-style: solid;
        border-width: 0px;
        border-color: white;
        height: 200px;
        width: 300px;
    }

    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        height: 200px;
        max-width: 300px;
    }
}
